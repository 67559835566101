import React, { useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro";

// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import * as yup from "yup";

// ========= UTILS =========
import { ENUMS } from "../libs/globals";

import useLogin from "../hooks/useLogin";

// ========= COMPONENTS =========
import NavBar from "../components/Elements/NavBar";

// ========= Assets =========
import backgroundImage from "../assets/img/backgrounds/BGWhite.jpg";

import SignIn from "../components/UserSettings/SignIn";
import SignUp from "../components/UserSettings/SignUp";
import UserSettings from "../components/UserSettings/UserSettings";

const BG = styled.div(() => [
	tw`
					antialiased
					w-screen h-full min-h-screen
					py-5 pl-5 lg:pl-48 pr-5
					bg-cover bg-center
					font-centra
					flex justify-center items-center flex-col
				`,
	css`
		background-color: #f1f5f3;
		background-image: url("${backgroundImage}");
	`,
]);

// ========= RENDER =========
const Settings = () => {
	const user = useLogin();

	const [shouldSignUp, setShouldSignUp] = useState(false);

	if (!user) return <></>;
	return (
		<BG>
			{user.isAnonymous ? (
				shouldSignUp ? (
					<SignUp />
				) : (
					<SignIn setShouldSignUp={setShouldSignUp} />
				)
			) : (
				<UserSettings user={user} />
			)}

			<NavBar activePage={ENUMS.NAV.SETTINGS} />
		</BG>
	);
};

export default Settings;
