import React, { useState, useEffect } from "react";
import tw, { css } from "twin.macro";

// ========= ASSETS =========
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle as settingsIcon } from "@fortawesome/free-regular-svg-icons";
import { faTimes as xIcon } from "@fortawesome/free-solid-svg-icons";

// ========= UTILS =========
import copy from "../../assets/copy";
import {
	deleteAccount,
	deletePledge,
	getPlayerState,
	signOut,
	updatePlayerState,
} from "../../libs/auth";

// ========= COMPONENTS =========
import MyPledge from "../Elements/MyPledge";
import { navigate } from "gatsby";

// ========= CUSTOM =========
const Container = tw.div`mx-auto w-full max-w-sm`;

const BottomButtons = () => (
	<div css={tw`flex justify-between items-center mt-8 px-4`}>
		<button
			type="submit"
			css={tw`bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-4 rounded-full focus:outline-none focus:shadow-outline text-sm`}
			onClick={() => {
				signOut();
				navigate(`/`);
			}}
		>
			Log out
		</button>
		<button
			type="submit"
			css={tw`flex items-center text-red hover:text-red-dark font-bold text-sm whitespace-no-wrap`}
			onClick={async () => {
				await deleteAccount();
				navigate(`/`);
			}}
		>
			<FontAwesomeIcon
				icon={settingsIcon}
				size="2x"
				css={[
					tw`lg:text-3xl lg:text-red border-4 border-transparent`,
				]}
			/>
			Delete Account
		</button>
	</div>
);

const Settings = ({
	user,
	email,
	emailOptIn,
	setEmailOptIn,
	pledge,
	pledgeOptIn,
	setPledgeOptIn,
}) => {
	return (
		<div
			css={[
				tw`w-full bg-white rounded-lg border-gray-400 border relative`,
				css`
					border-radius: 40px;
				`,
			]}
		>
			<div css={tw`pt-6 pb-8 px-8`}>
				<h3
					css={tw`font-bold text-lg pl-4 mb-2 uppercase leading-snug`}
				>
					USER SETTINGS
				</h3>

				<div css={tw`pl-4 text-lg leading-snug`}>
					{email}

					<p
						css={[
							tw`font-normal text-xs text-gray-400 `,
						]}
					>
						{user?.uid}
					</p>
				</div>

				<label
					htmlFor="mailing-list"
					css={tw`block text-sm flex items-start mt-4 px-4`}
				>
					<input
						id="mailing-list"
						name="mailing-list"
						type="checkbox"
						css={tw`mt-1 mr-2 flex-shrink-0`}
						checked={emailOptIn ?? false}
						onChange={() => setEmailOptIn(!emailOptIn)}
					/>
					<span css={tw`block`}>
						{copy.registerCard.optIn}
					</span>
				</label>
			</div>

			<div
				css={tw`border-t border-gray-400 pt-6 pb-8 px-8 mb-2`}
			>
				<MyPledge />
				{pledge && (
					<>
						<div
							css={[
								tw`px-4 text-xs text-gray-500 mt-1`,
								css`
									> span {
										${tw`pr-4`}
									}
								`,
							]}
						>
							<span
								tw="cursor-pointer"
								onClick={() => {
									deletePledge();
									location.reload();
								}}
							>
								<FontAwesomeIcon icon={xIcon} />
								{` `}
								{copy.signUp.delete}
							</span>
						</div>

						<label
							htmlFor="pledge-wall"
							css={tw`block text-sm flex items-start mt-4 px-4`}
						>
							<input
								id="pledge-wall"
								name="pledge-wall"
								type="checkbox"
								css={tw`mt-1 mr-2`}
								checked={pledgeOptIn ?? false}
								onChange={() =>
									setPledgeOptIn(!pledgeOptIn)
								}
							/>
							<span css={tw`block`}>
								{copy.pledge.post}
							</span>
						</label>
					</>
				)}
			</div>
		</div>
	);
};

const UserSettings = ({ user }) => {
	const [isAnonymous, setIsAnonymous] = useState();

	const [email, setEmail] = useState();
	const [emailOptIn, setEmailOptIn] = useState(false);

	const [pledge, setPledge] = useState();
	const [pledgeOptIn, setPledgeOptIn] = useState(false);

	useEffect(() => {
		if (!user) return;

		async function fetchPlayerState() {
			const ps = await getPlayerState();

			setIsAnonymous(ps.isAnonymous);

			setEmail(user.email);
			setEmailOptIn(ps?.emailOptIn);

			setPledge(ps?.pledge);
			setPledgeOptIn(ps?.pledge?.pledgeWall);
		}

		fetchPlayerState();
	}, [user]);

	// when the email opt in changes, set it in firebase
	useEffect(() => {
		if (!email) return;
		if (emailOptIn === undefined) return;
		updatePlayerState({ newsletter: emailOptIn });

		if (emailOptIn) {
			fetch(
				`https://us-central1-pen-america-quiz.cloudfunctions.net/subscribeToNewsletter?email=${email}`,
				{ mode: `no-cors` }
			);
		} else {
			fetch(
				`https://us-central1-pen-america-quiz.cloudfunctions.net/unsubscribeFromNewsletter?email=${email}`,
				{ mode: `no-cors` }
			);
		}
	}, [emailOptIn]);

	// when the pledge wall opt in changes, set it in firebase
	useEffect(() => {
		if (pledgeOptIn === undefined) return;
		setPledge({ ...pledge, pledgeWall: pledgeOptIn });
	}, [pledgeOptIn]);
	useEffect(() => {
		if (!pledge) return;
		updatePlayerState({ pledge });
	}, [pledge]);

	// ========= RENDER =========
	return (
		<Container>
			<Settings
				user={user}
				isAnonymous={isAnonymous}
				email={email}
				emailOptIn={emailOptIn}
				setEmailOptIn={setEmailOptIn}
				pledge={pledge}
				pledgeOptIn={pledgeOptIn}
				setPledgeOptIn={setPledgeOptIn}
			/>
			<BottomButtons />
		</Container>
	);
};

export default UserSettings;
