import React, { useState } from "react";
import tw, { css } from "twin.macro";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import { sendPasswordlessEmail } from "../../libs/auth";
import copy from "../../assets/copy";

// ========= HELPERS =========

// ========= MAIN =========
const SignUp = () => {
	const [emailSent, setEmailSent] = useState(false);

	const { register, handleSubmit } = useForm({
		resolver: yupResolver(
			yup.object().shape({
				email: yup.string().email().required(),
			})
		),
	});

	const onSubmitEmail = ({ email }) => {
		sendPasswordlessEmail({ email });
		setEmailSent(true);
	};

	// ========= RENDER =========
	return (
		<div
			css={tw`
					mx-auto w-full max-w-sm
				`}
		>
			<div
				css={[
					tw`w-full bg-white border-gray-400 border py-32 px-8 mb-2 flex flex-col items-center justify-center`,
					css`
						border-radius: 40px;
					`,
				]}
			>
				{!emailSent ? (
					<>
						<h3
							css={tw`font-bold text-4xl uppercase text-red text-center mb-8`}
						>
							{copy.signUp.signUp}
						</h3>
						<form
							css={tw`w-full`}
							onSubmit={handleSubmit(onSubmitEmail)}
						>
							<input
								name="email"
								type="text"
								id="email"
								placeholder="Email"
								ref={register({
									required: true,
								})}
								css={tw`bg-gray-200 border border-gray-400 w-full py-2 px-4 w-full mb-6 rounded-full focus:outline-none focus:bg-white transition-all`}
							/>
							<label
								htmlFor="mailing-list"
								css={tw`block text-sm flex items-start mb-8 px-6`}
							>
								<input
									id="mailing-list"
									name="mailing-list"
									type="checkbox"
									css={tw`mt-1 mr-2`}
								/>
								<span css={tw`block`}>
									{copy.registerCard.optIn}
								</span>
							</label>
							<div css={tw`text-center`}>
								<button
									type="submit"
									css={tw`inline-block bg-black hover:bg-red text-white font-bold py-2 px-8 rounded-full text-center focus:outline-none focus:shadow-outline`}
								>
									{copy.signUp.submit}
								</button>
							</div>
						</form>
					</>
				) : (
					<>
						<h2 tw="text-red text-2xl font-bold uppercase mb-4">
							{copy.signUp.thanks}
						</h2>
						<p tw="text-center">
							{copy.signUp.instructions}
						</p>
					</>
				)}
			</div>
		</div>
	);
};

export default SignUp;
